.cv-auth-display-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cv-auth-error-message {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cv-auth-error-message button {
    padding: 8px;
    margin-top: 4px;
    width: fit-content;
    background-color: #1d6ed2;
    color: #fff;
    border: none;
    border-radius: 5px;
}
