.cv-error-marvin {
    right: unset !important;
}

.cv-flex-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cv-gap-12 {
    gap: 12px;
}

.cv-fs-fullpage {
    height: 100vh;
}

.cv-error-platform .cv-header-error-page-minimized {
    height: 48px;
    min-height: unset;
}

.cv-header-error-page-minimized.cv-recent-contacts-widget-container {
    /* border: unset; */
}

.cv-error-platform .cv-error-page-minimized {
    height: 0;
    /* width: 170px; */
}

.cv-error-try-again-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    height: 32px;
    background: #006fd6;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 0px 12px;
    border-radius: 2px;
}

.cv-error-marvin .cv-recent-contacts-widget-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.cv-widgets-holder.cv-error-marvin {
    bottom: unset;
    width: 100%;
    right: unset;
}

.cv-error-marvin .cv-recent-contacts-widget-container {
    max-width: unset;
    max-height: unset;
    height: 100vh;
    width: 100vw;
    border: none !important;
}

.cv-error-marvin
    .cv-recent-contacts-widget-container.cv-header-error-page-minimized {
    /* height: 41px; */
}

.cv-error-marvin .cv-error-rc-header-minimized {
    /* height: 100vh; */
}

.cv-error-marvin .cv-rc-widget-body {
    transition: 200ms;
}

.cv-error-marvin .cv-rc-widget-header-wrapper {
    max-height: 41px;
}

.cv-error-marvin .cv-header-error-page-minimized .cv-rc-widget-header {
    /* align-self: flex-end; */
}

.cv-error-marvin .cv-rc-widget-header-wrapper.cv-error-rc-header-minimized {
    /* width: 170px; */
    border-radius: 0px !important;
}

/* TODO: MINIMIZE CSS */
