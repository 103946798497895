.cv-banner-tray {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
}

.cv-banner-wrapper {
    display: flex;
    padding: 3px 10px 3px 10px;
    min-height: 30px;
    max-height: 90px;
    box-sizing: border-box;
    margin-bottom: 2px;
    z-index: 10;
}

.cv-widget-wrapper .cv-banner-wrapper {
    padding: 0px 5px 4px 10px;
}

.cv-banner-wrapper:not(:first-child) {
    margin-top: 2px;
}

.cv-banner-wrapper.hidden {
    opacity: 0;
    transition: 300ms;
}

.cv-banner-action {
    margin-left: auto;
}

.cv-banner-content {
    margin-left: auto;
}

.cv-banner-content span {
    font-size: 13px;
    font-family: SemiBold;
    font-weight: var(--marvin-fw-semibold);
    color: #8a6116;
}

.cv-banner-content-mobile span {
    color: #212b36;
}

.cv-widget-wrapper .cv-banner-content span {
    font-size: 10.5px;
}

.cv-widget-wrapper .cv-banner-content a {
    font-size: 10.5px;
}

.cv-banner-wrapper.cv-success-banner {
    background-color: #50b83c;
}

.cv-banner-wrapper.cv-warning-banner {
    background-color: #e8ae53;
}

.cv-banner-wrapper.cv-failure-banner {
    background-color: #fcf1cd;
}

.cv-banner-close img {
    height: 20px;
    width: 20px;
    margin-top: 1px;
    cursor: pointer;
}

.cv-widget-wrapper .cv-banner-close img {
    width: 16px;
    height: 16px;
}

.cv-widget-wrapper .cv-banner-action {
    margin-top: 3px;
}
